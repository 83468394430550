









































































































































































import { Ax } from '@/utils';
import { Vue, Component } from 'vue-property-decorator';
import { store } from './common';


const paths = {
    initial: '/staffing-table',

    config: '/staffing-table/config',
    data: '/staffing-table/data',
    departmentPositions: '/staffing-table/department-positions',
    departments: '/staffing-table/departments',
    reports: '/staffing-table/reports',
    report: '/staffing-table/report/',
    report2: '/staffing-table/report-2/',
    fileStorage: '/staffing-table/files-storage',
    versions: '/staffing-table/versions',
};

@Component({
    name: 'StaffingTable',
})
export default class Page extends Vue {
    // region Инструкции
    private infoInstructions() {
        const baseManual = 'baseManual';
        Ax(
            {
                url: '/api/budget/staffing_table/instructions' + baseManual, //  Тело файла
                method: 'GET',
                data: null,
                responseType: 'blob'
            },
            (data) => {
                const url = window.URL.createObjectURL(new Blob([data as BlobPart]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = 'Инструкция пользователя «Штатное расписание»'; // TODO translate

                link.setAttribute('download', fileName + '.pdf');
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                console.error(error.key);
            }
        );
    }

    // noinspection JSMethodCanBeStatic
    private openVideoInstruction() {
        window.open('https://www.youtube.com/playlist?list=PL4jwFLRAXDin-5KQiIzRDPloySgGNkOVg');
    }
    // endregion


    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('path', () => this.checkOpenedTab());
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.checkOpenedTab();
    }
    // endregion


    // region Общее
    private get path(): string {
        return this.$route.path;
    }

    private set path(value: string) {
        this.$router.push({ path: value });
    }

    private replacePath(value: string) {
        this.$router.replace({ path: value });
    }

    private checkOpenedTab() {
        if (!(
            this.isConfigTabActive
            || this.isDataTabActive
            || this.isDepartmentsTabActive
            || this.isDepartmentPositionsTabActive
            || this.isReportsTabActive
            || this.isFileStorageTabActive
            || this.isVersionsTabActive
        )) {
            this.replacePath(paths.data);
        }
    }

    private get storeInitialized(): boolean { return store.initialized; }
    // endregion


    // region Настройка
    private onConfigTabClicked() {
        this.path = paths.config;
    }

    private get isConfigTabActive(): boolean {
        return (this.path === paths.config);
    }
    // endregion


    // region Штатное расписание
    private onDataTabClicked() {
        this.path = paths.data;
    }

    private get isDataTabActive(): boolean {
        return this.path === paths.data;
    }
    // endregion


    // region Подразделения ГУ
    private onDepartmentsTabClicked() {
        this.path = paths.departments;
    }

    private get isDepartmentsTabActive(): boolean {
        return (this.path === paths.departments);
    }
    // endregion


    // region Должности в подразделениях ГУ
    private onDepartmentPositionsTabClicked() {
        this.path = paths.departmentPositions;
    }

    private get isDepartmentPositionsTabActive() {
        return (this.path === paths.departmentPositions);
    }
    // endregion


    // region Отчеты
    private onReportsTabClicked() {
        this.path = paths.reports;
    }

    private get isReportsTabActive() {
        return ((this.path === paths.reports) || this.path.startsWith(paths.report) || this.path.startsWith(paths.report2));
    }
    // endregion


    // region Файловое хранилище

    private onFileStorageTabClicked() {
        this.path = paths.fileStorage;
    }

    private get isFileStorageTabActive() {
        return (this.path === paths.fileStorage);
    }
    // endregion


    // region Версии
    private onVersionsTabClicked() {
        this.path = paths.versions;
    }

    private get isVersionsTabActive(): boolean {
        return (this.path === paths.versions);
    }
    // endregion


    private startGame() {
        const backdrop = document.createElement('div');
        backdrop.setAttribute('style', [
            'position: fixed;',
            'top: 0;',
            'left: 0;',
            'right: 0;',
            'bottom: 0;',
            'background-color: rgba(0, 0, 0, 0.315);',
            'z-index: 10000;',
        ].join(' '));

        const modal = document.createElement('iframe');
        modal.setAttribute('style', [
            'position: absolute;',
            'left: calc((100vw - 802px) / 2);',
            'top: calc((100vh - 622px) / 2);',
            'background-color: white;',
            'border-radius: 3px;',
            'border: 1px solid grey;',
            'width: 802px;',
            'height: 622px;',
        ].join(' '));
        modal.src = '/media/game/index.html';

        document.body.append(backdrop);
        backdrop.append(modal);

        backdrop.addEventListener('click', () => { backdrop.remove(); });
    }
}
